.Notification__icon___GPDHD {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  width: 30px;
  text-align: center; }

.Notification__content___2i_Va {
  border-radius: 0 2px 2px 0; }

.Notification__item___1d3Zz, .Notification__item__message___3NDDt, .Notification__item__btnBar___2ulqW {
  padding: 10px;
  font-weight: 900;
  font-family: 'Lato', sans-serif;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-font-smoothing: antialiased; }
  .Notification__item__message___3NDDt {
    font-size: 0.9rem;
    max-height: 150px;
    overflow-y: auto;
    border-top: none; }
    .Notification__item__message___3NDDt p:last-child {
      margin-bottom: 0; }
  .Notification__item__btnBar___2ulqW {
    margin-top: 10px;
    padding: 0; }

.Notification__actionBtn___3o--x {
  box-sizing: border-box;
  background: none;
  font-size: 0.9rem;
  font-family: 'Lato', sans-serif;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  text-shadow: none;
  cursor: pointer;
  display: inline-block;
  width: 50%;
  height: 30px;
  line-height: 30px;
  text-align: center; }
  .Notification__actionBtn___3o--x:hover {
    background: none; }
  .Notification__actionBtn___3o--x:last-child {
    border-right: none; }
  .Notification__actionBtn___3o--x i {
    margin-right: 7px; }

.Notification__close-all___3YIP0, .Notification__has-close-all--noDismiss___3oZHJ .Notification__close-all___3YIP0 {
  position: absolute;
  top: 7px;
  right: 38px;
  background: none;
  font-size: 0.9rem;
  font-family: 'Lato', sans-serif;
  text-shadow: none;
  padding: 1px 5px;
  border-radius: 3px;
  cursor: pointer; }
  .Notification__close-all___3YIP0:hover {
    background: none; }

.Notification__close___CZE_b {
  position: absolute;
  top: 0;
  right: 0;
  width: 38px;
  height: 38px;
  font-size: 0.7rem;
  line-height: 38px;
  text-align: center;
  text-shadow: none;
  color: black;
  opacity: .4;
  cursor: pointer; }
  .Notification__close___CZE_b:hover, .Notification__close___CZE_b:focus {
    opacity: 1; }
  .Notification__close___CZE_b:active {
    opacity: .2; }
  .Notification__close___CZE_b:before {
    content: "\274C";
    margin: 0; }

.Notification__no-close___3-p_Q .Notification__item--message___2JYsT {
  padding-right: 10px; }

.Notification__has-close-all--noDismiss___3oZHJ .Notification__close-all___3YIP0 {
  right: 8px; }

.Notification__has-close-all--noDismiss___3oZHJ .Notification__item--message___2JYsT {
  padding-right: 81px; }

.Notification__has-close-all___3-_VI .Notification__item--message___2JYsT {
  padding-right: 115px; }

.Notification__has-close___2d--3 .Notification__item--message___2JYsT {
  padding-right: 34px; }

.Notification__notification--info___1cxtE {
  position: relative;
  word-wrap: break-word;
  pointer-events: auto;
  width: 300px;
  border-radius: 5px;
  background: #80ccff;
  padding-left: 30px;
  overflow: hidden;
  z-index: 9999;
  margin-bottom: 2px;
  max-height: 150px; }
  .Notification__notification--info___1cxtE .Notification__icon___GPDHD {
    color: #e8f1fa;
    background: #0098ff; }
  .Notification__notification--info___1cxtE .Notification__content___2i_Va {
    color: #112e4d;
    background-color: #80ccff; }
  .Notification__notification--info___1cxtE .Notification__actionBtn___3o--x {
    color: rgba(17, 46, 77, 0.6); }
    .Notification__notification--info___1cxtE .Notification__actionBtn___3o--x:hover {
      color: #112e4d; }
  .Notification__notification--info___1cxtE .Notification__close-all___3YIP0 {
    border: 1px solid rgba(17, 46, 77, 0.3);
    color: rgba(17, 46, 77, 0.6); }
    .Notification__notification--info___1cxtE .Notification__close-all___3YIP0:hover {
      border-color: rgba(17, 46, 77, 0.8);
      color: #112e4d; }

.Notification__notification--success___2txWa {
  position: relative;
  word-wrap: break-word;
  pointer-events: auto;
  width: 300px;
  border-radius: 5px;
  background: #72efa8;
  padding-left: 30px;
  overflow: hidden;
  z-index: 9999;
  margin-bottom: 2px;
  max-height: 150px; }
  .Notification__notification--success___2txWa .Notification__icon___GPDHD {
    color: #c6f9dd;
    background: #17ca65; }
  .Notification__notification--success___2txWa .Notification__content___2i_Va {
    color: #05371b;
    background-color: #72efa8; }
  .Notification__notification--success___2txWa .Notification__actionBtn___3o--x {
    color: rgba(5, 55, 27, 0.6); }
    .Notification__notification--success___2txWa .Notification__actionBtn___3o--x:hover {
      color: #05371b; }
  .Notification__notification--success___2txWa .Notification__close-all___3YIP0 {
    border: 1px solid rgba(5, 55, 27, 0.3);
    color: rgba(5, 55, 27, 0.6); }
    .Notification__notification--success___2txWa .Notification__close-all___3YIP0:hover {
      border-color: rgba(5, 55, 27, 0.8);
      color: #05371b; }

.Notification__notification--warning___Wd6Y7 {
  position: relative;
  word-wrap: break-word;
  pointer-events: auto;
  width: 300px;
  border-radius: 5px;
  background: #ffa480;
  padding-left: 30px;
  overflow: hidden;
  z-index: 9999;
  margin-bottom: 2px;
  max-height: 150px; }
  .Notification__notification--warning___Wd6Y7 .Notification__icon___GPDHD {
    color: #fff9f6;
    background: #ff4800; }
  .Notification__notification--warning___Wd6Y7 .Notification__content___2i_Va {
    color: #6c2c05;
    background-color: #ffa480; }
  .Notification__notification--warning___Wd6Y7 .Notification__actionBtn___3o--x {
    color: rgba(108, 44, 5, 0.6); }
    .Notification__notification--warning___Wd6Y7 .Notification__actionBtn___3o--x:hover {
      color: #6c2c05; }
  .Notification__notification--warning___Wd6Y7 .Notification__close-all___3YIP0 {
    border: 1px solid rgba(108, 44, 5, 0.3);
    color: rgba(108, 44, 5, 0.6); }
    .Notification__notification--warning___Wd6Y7 .Notification__close-all___3YIP0:hover {
      border-color: rgba(108, 44, 5, 0.8);
      color: #6c2c05; }

.Notification__notification--error___2fV5G {
  position: relative;
  word-wrap: break-word;
  pointer-events: auto;
  width: 300px;
  border-radius: 5px;
  background: #ff4d4d;
  padding-left: 30px;
  overflow: hidden;
  z-index: 9999;
  margin-bottom: 2px;
  max-height: 150px; }
  .Notification__notification--error___2fV5G .Notification__icon___GPDHD {
    color: #ff8585;
    background: #c00; }
  .Notification__notification--error___2fV5G .Notification__content___2i_Va {
    color: black;
    background-color: #ff4d4d; }
  .Notification__notification--error___2fV5G .Notification__actionBtn___3o--x {
    color: rgba(0, 0, 0, 0.6); }
    .Notification__notification--error___2fV5G .Notification__actionBtn___3o--x:hover {
      color: black; }
  .Notification__notification--error___2fV5G .Notification__close-all___3YIP0 {
    border: 1px solid rgba(0, 0, 0, 0.3);
    color: rgba(0, 0, 0, 0.6); }
    .Notification__notification--error___2fV5G .Notification__close-all___3YIP0:hover {
      border-color: rgba(0, 0, 0, 0.8);
      color: black; }
@keyframes Notify__notification-show___m91vc {
  0% {
    opacity: 0;
    transform: perspective(300px) translate(0, -30px) rotateX(90deg); }
  100% {
    opacity: 1;
    transform: perspective(300px) translate(0, 0) rotateX(0deg); } }

@keyframes Notify__notification-hide___qXZMa {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.8); } }

@keyframes Notify__notification-shrink___dlvYw {
  0% {
    opacity: 0;
    max-height: 150px;
    transform: scale(0.8); }
  100% {
    opacity: 0;
    max-height: 0;
    transform: scale(0.8); } }

.Notify__wrapper___Btp8G {
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  font-size: 1.2em;
  min-height: 0;
  -ms-flex-direction: inherit;
      flex-direction: inherit; }

.Notify__containerTopRight___3Aype {
  z-index: 9998;
  position: fixed;
  overflow-y: auto;
  max-height: 100vh;
  top: 0;
  right: 0;
  -ms-flex-direction: column;
      flex-direction: column; }
  .Notify__containerTopRight___3Aype::-webkit-scrollbar {
    display: none; }

.Notify__containerBottomRight___2LKUe {
  z-index: 9998;
  position: fixed;
  overflow-y: auto;
  max-height: 100vh;
  right: 0;
  bottom: 0;
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; }
  .Notify__containerBottomRight___2LKUe::-webkit-scrollbar {
    display: none; }

.Notify__containerBottomLeft___1zAHL {
  z-index: 9998;
  position: fixed;
  overflow-y: auto;
  max-height: 100vh;
  left: 0;
  bottom: 0;
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; }
  .Notify__containerBottomLeft___1zAHL::-webkit-scrollbar {
    display: none; }

.Notify__containerTopLeft___1Mczb {
  z-index: 9998;
  position: fixed;
  overflow-y: auto;
  max-height: 100vh;
  top: 0;
  left: 0;
  -ms-flex-direction: column;
      flex-direction: column; }
  .Notify__containerTopLeft___1Mczb::-webkit-scrollbar {
    display: none; }

.Notify__enter___1lTas {
  animation: Notify__notification-show___m91vc 0.16s cubic-bezier(0.175, 0.885, 0.32, 1.27499); }

.Notify__leave___IHSth {
  animation: Notify__notification-hide___qXZMa 0.24s cubic-bezier(0.33859, -0.42, 1, -0.22), Notify__notification-shrink___dlvYw 0.24s 0.24s cubic-bezier(0.5, 0, 0, 1); }
